'use client'
import { useState } from 'react'
import { PrismicNextImage } from "@prismicio/next";

interface LinkPanelTileProps {
    page?: any | null
    updateResultList?: any | null
}

const LinkPanelTile: React.FC<LinkPanelTileProps> = ({ page, updateResultList }) => {

    const [filterSet, setFilterSet] = useState({}) as any;

    const addFilter = (e: any, item: string, type: string) => {
        const filterEdit = { ...filterSet };
    
        if (!filterEdit[type]) {
            filterEdit[type] = [];
        }

        filterEdit[type].push(item);
        setFilterSet(filterEdit);
        updateResultList(filterEdit);
    };

    return (
        <div className="p-0 m-0" onClick={(e: any) => { addFilter(e, page.uid, "productSubtype") }}>
            <h4 className="text-left capitalize font-norma text-warmcharcoal text-[16px] leading-[24px] mb-[44px] font-medium px-[10px]">{page.uid?.replace(/---/g, " + ").replace(/-/g, " ")}</h4>
            <div className="block px-[10px]">ccc
                <PrismicNextImage field={page.data.meta_image} fallbackAlt="" className="aspect-square object-cover"/>
            </div>
            <div className="text-offwhite font-medium font-norma text-[14px] leading-[20px] flex items-center justify-center w-full bg-warmcharcoal absolute bottom-0 h-[42px]">Shop below</div>
        </div>
    )
}

export default LinkPanelTile