'use client';
import { useState, useEffect, useRef } from 'react';
import { useWindowScroll } from 'react-use';
import _ from 'lodash';
import Tick from '@/assets/icons/tick.svg';
import Close from '@/assets/icons/close.svg';
import stc from 'string-to-color'
import cloneDeep from 'lodash/cloneDeep';
import { PrismicNextImage } from "@prismicio/next";
import ArrowRight from '@/assets/icons/right-arrow.svg'

interface FiltersProps {
    updateResultList?: any | null;
    results?: any | null;
    quickFind?: any | null;
    collectionProjectsArray?: any | null
}

const Filters: React.FC<FiltersProps> = ({ updateResultList, results, quickFind, collectionProjectsArray }) => {
    const [filters, setFilters] = useState([]) as any;
    const [filterSet, setFilterSet] = useState({}) as any;
    const [filterOpen, setFilterOpen] = useState(0);
    const [offsetTop, setOffsetTop] = useState(0);


    const quickFilter = useRef(null) as any

    function removeItemFromAllArrays(object: any, word: any) {
        for (const key in object) {
            if (object[key] && Array.isArray(object[key])) {
                object[key] = object[key].filter(item => item !== word);
            }
        }
    }

    const removeItem = (item: any, type: string) => {
        const filterEdit = { ...filterSet };
        filterEdit[type] = filterEdit[type].filter((i: string) => i !== item);
        // Remove the tick from the associated text box in the filter panel
        document.querySelectorAll('.filter-list .bg-warmcharcoal').forEach(element => {
            const htmlElement = element as HTMLElement;
            if (htmlElement.innerText.includes(item)) {
                htmlElement.classList.remove('bg-warmcharcoal');
                if (htmlElement.children[0]) {
                    (htmlElement.children[0] as HTMLElement).classList.add('hidden');
                }
            }
        });

        setFilterSet(filterEdit);
        updateResultList(filterEdit);
    }

    const getFilters = async () => {
        const getAllFilters = await fetch('/api/filters');
        const allFilters = await getAllFilters.json();
        setFilters(allFilters.filters);
    }

    const clearAllFilters = () => {
        const filterEdit = { ...filterSet };
        Object.keys(filterEdit).forEach(key => {
            filterEdit[key] = [];
        });

        document.querySelectorAll('.bg-warmcharcoal').forEach(element => {
            element.classList.remove('bg-warmcharcoal');
            if (element.children[0]) {
                element.children[0].classList.add('hidden');
            }
        });

        setFilterSet(filterEdit);
        updateResultList(filterEdit);
    };

    const addFilter = (e: any, item: string, type: string) => {
        const filterEdit = { ...filterSet };

        if (!filterEdit[type]) {
            filterEdit[type] = [];
        }

        const sibling = e.target?.parentElement?.children[1];
        if (sibling?.classList.contains('bg-warmcharcoal')) {
            if (sibling.children[0]) {
                sibling.children[0].classList.add('hidden');
            }
            sibling.classList.remove('bg-warmcharcoal');
            filterEdit[type] = filterEdit[type].filter((i: string) => i !== item);
        } else {
            if (sibling?.children[0]) {
                sibling.children[0].classList.remove('hidden');
            }
            sibling.classList.add('bg-warmcharcoal');
            filterEdit[type].push(item);
        }
        setFilterSet(filterEdit);
        updateResultList(filterEdit);
    };

    const convertString = (str: string) => {
        return str
          .replace(/-{2,}/g, ' + ') // Replace multiple dashes with ' + '
          .replace(/-/g, ' ')        // Replace single dashes with spaces
          .replace(/\b\w/g, (match: any) => match.toUpperCase()); // Capitalize first letter of each word
    }

    const addQuickFind = (item: string, type: string) => {
        let searchType = '';
        let uidSet = [] as any;
    
        switch (type) {
            case 'product':
                searchType = 'productSubtype';
                break;
            case 'collection_section':
                searchType = 'projectName';
                uidSet = collectionProjectsArray.flatMap((colItem: any) => 
                    colItem.items.map((item: any) => item.collection_page.uid)
                );
                break;
            default:
                searchType = '';
        }
    
        if (!searchType) {
            console.error("Invalid filter type:", type);
            return;
        }
    
        // Spread the existing filterSet to maintain previous filters
        const filterEdit = { ...filterSet }; 
    
        // Ensure both filter types (productSubtype and projectName) are initialized
        if (!filterEdit.productSubtype) {
            filterEdit.productSubtype = [];
        }
        if (!filterEdit.projectName) {
            filterEdit.projectName = [];
        }
    
        if (searchType === "productSubtype") {
            const searchKey = convertString(item);
            if (filterEdit.productSubtype.includes(searchKey)) {
                filterEdit.productSubtype = filterEdit.productSubtype.filter((i: string) => i !== searchKey);
            } else {
                filterEdit.productSubtype.push(searchKey);
            }
        }
    
        if (searchType === "projectName") {
            uidSet?.map((uidItem: any) => {
                const formattedItem = uidItem.replace(/-/g, ' ');
                if (filterEdit.projectName.includes(formattedItem)) {
                    filterEdit.projectName = filterEdit.projectName.filter((i: string) => i !== formattedItem);
                } else {
                    filterEdit.projectName.push(formattedItem);
                }
            });
        }
    
        // Ensure you're not overwriting unrelated filters and only updating relevant filters
        setFilterSet(filterEdit); 
    
        // Update the results based on the combined filters
        updateResultList(filterEdit);

        setFilterOpen(-1);
        resultsRef.current?.classList.add('h-[350px]', 'md:h-[250px]');
        resultsRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    
    

    useEffect(() => {
        setOffsetTop(filterRef?.current?.offsetTop);
        getFilters();
    }, []);

    // console.log(filters)
    const filterRef = useRef(null) as any;
    const resultsRef = useRef(null) as any;
    const { x, y } = useWindowScroll();

    useEffect(() => {
        if (y + 60 >= filterRef?.current?.offsetTop) {
            filterRef?.current?.classList.add("fixed");
        } else {
            filterRef?.current?.classList.remove("fixed");
        }
    }, [y]);

    const isItemChecked = (item: string, type: string) => {
        return filterSet[type]?.includes(item);
    };

    const [currentIndex, setCurrentIndex] = useState(0);
    const tileRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [tileWidth, setTileWidth] = useState(0);

    useEffect(() => {
    if (tileRef.current) {
        // Get the width of the tile
        const tileElement = tileRef.current.getBoundingClientRect();
        
        // Set the tile width without adding the gap here
        setTileWidth(tileElement.width); 

        // console.log("Tile Width:", tileElement.width); // Debug the width
    }
    }, []);

    const moveLeft = () => {
    if (containerRef.current && tileWidth) {
        // Adjust the scroll position by exactly one tile + gap (scrolling one tile)
        const newScrollPos = containerRef.current.scrollLeft - tileWidth - 20; // Account for 20px gap
        // console.log("Scrolling Left: New Scroll Position:", newScrollPos); // Debug scroll position
        containerRef.current.scrollTo({ left: newScrollPos, behavior: 'smooth' });
        setCurrentIndex((prev) => Math.max(prev - 1, 0));
    }
    };

    const moveRight = () => {
    if (containerRef.current && tileWidth) {
        // Adjust the scroll position by exactly one tile + gap (scrolling one tile)
        const newScrollPos = containerRef.current.scrollLeft + tileWidth + 20; // Account for 20px gap
        // console.log("Scrolling Right: New Scroll Position:", newScrollPos); // Debug scroll position
        containerRef.current.scrollTo({ left: newScrollPos, behavior: 'smooth' });
        setCurrentIndex((prev) => Math.min(prev + 1, quickFind.pageData.results.length - 1));
    }
    };

    // Recalculate tile width on resize
    useEffect(() => {
    const handleResize = () => {
        if (tileRef.current) {
        const tileElement = tileRef.current.getBoundingClientRect();
        setTileWidth(tileElement.width/2); // Recalculate tile width
        //   console.log("Resized Tile Width:", tileElement.width); // Debug resized width
        }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial calculation

    return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
        {quickFind ?
            <section
                data-slice-type={quickFind.slice_type}
                data-slice-variation={quickFind.variation}
                className="py-[50px] pb-[134px] lg:px-6"
                >
                <h3 className="px-4 md:px-8 text-[24px] leading-[32px] text-warmcharcoal font-sangbleu font-medium mb-12">{quickFind.primary.heading}</h3>
                <div className="relative w-full overflow-x-scroll lg:overflow-hidden scrollbar-hide" ref={containerRef}>
                    <div className="flex items-between gap-4 lg:gap-8 px-4 md:px-8 w-[1280px] lg:w-auto" style={{ transform: `translateX(-${currentIndex * tileWidth}px)` }}>
                        {quickFind?.pageData.results.map((page: any, index:number)=>{
                        return (
                            <div className="bg-lightwarmgrey flex pt-[10px] relative cursor-pointer hover:opacity-60 lg:w-full" ref={index === 0 ? tileRef : null}  key={index} onClick={(e: any) => { e.target.classList.toggle("border-warmcharcoal"); addQuickFind(page.uid, page.type)}}>
                                <div className="p-0 m-0 pb-[54px]">
                                    <h4 className="text-left capitalize font-norma text-warmcharcoal text-[16px] leading-[24px] mb-[44px] font-medium px-[10px]">{page.uid?.replace(/---/g, " + ").replace(/-/g, " ")}</h4>
                                    <div className="block px-[10px]">
                                        <PrismicNextImage field={page.data.meta_image} fallbackAlt="" className="aspect-square object-cover"/>
                                    </div>
                                    <div className="text-offwhite font-medium font-norma text-[14px] leading-[20px] flex items-center justify-center w-full bg-warmcharcoal absolute bottom-0 h-[42px]">Shop below</div>
                                </div>
                            </div>
                        )
                        })}
                    </div>
                </div>
                <div className="mt-6 w-[100px] mx-auto flex justify-between lg:hidden">
                    <button className="" onClick={moveLeft}><ArrowRight className="stroke-warmcharcoal"/></button>
                    <button className="" onClick={moveRight}><ArrowRight className="stroke-warmcharcoal rotate-180"/></button>
                </div>      
            </section>
        : <></>}
        <section className="border-t-[1px] border-t-warmcharcoal w-full font-norma text-[14px] left-0 top-[68px] bg-offwhite z-20" ref={filterRef}>
            <div className="grid grid-cols-12 py-4 px-4 lg:px-[50px] ">
                <div className="col-span-6 md:col-span-1 mb-4 md:mb-0">
                    <p className="">Filter by: {filterSet?.length > 0 ? "("+filterSet?.length+")" : ""}</p>
                </div>
                <div className="col-span-6 md:hidden flex justify-end">
                    <p className="font-medium md:text-right">{results} Products</p>
                </div>
                <div className="col-span-3 md:col-span-2">
                    <p className="cursor-pointer" onClick={() => filterOpen === 1 ? setFilterOpen(0) : setFilterOpen(1)}>Product</p>
                    <div className={(filterOpen === 1 ? "block" : "hidden") + " bg-lightwarmgrey pt-4 px-4 md:px-[50px] absolute left-0 right-0 z-[1] mt-4 pb-8 min-h-[90dvh] md:min-h-[350px]"}>
                        <Close onClick={() => {setFilterOpen(-1)}} className="absolute z-[1002] right-4 lg:right-[50px] top-4 lg:top-6 stroke-warmcharcoal cursor-pointer hover:opacity-60"/>
                        <p className="underline underline-offset-4 mb-8 cursor-pointer hover:opacity-60" onClick={() => clearAllFilters()}>Clear all</p>
                        <ul className="filter-list" ref={quickFilter}>
                            {filters?.type?.map((type: any, index: number) => {
                                return (
                                    <li key={index} className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                        <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={(e: any) => { addFilter(e, type.productsubtype, "productSubtype"); setFilterOpen(0) }}></div>
                                        <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${isItemChecked(type.productsubtype, "productSubtype") ? 'bg-warmcharcoal' : ''}`}>
                                            <Tick className={`${isItemChecked(type.productsubtype, "productSubtype") ? '' : 'hidden'} relative tick`} />
                                        </span>
                                        <span className="relative -top-[5px]">{type.productsubtype}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="col-span-3 md:col-span-2" >
                    <p className="cursor-pointer" onClick={() => filterOpen === 2 ? setFilterOpen(0) : setFilterOpen(2)}>Fabric</p>
                    <div className={(filterOpen === 2 ? "block" : "hidden") + " bg-lightwarmgrey pt-4 px-4 md:px-[50px] absolute left-0 right-0 z-[1] mt-4 pb-8 min-h-[90dvh] md:min-h-[350px]"}>
                        <Close onClick={() => {setFilterOpen(-1)}} className="absolute z-[1002] right-4 lg:right-[50px] top-4 lg:top-6 stroke-warmcharcoal cursor-pointer hover:opacity-60"/>
                        <p className="underline underline-offset-4 mb-8 cursor-pointer hover:opacity-60" onClick={() => clearAllFilters()}>Clear all</p>
                        <ul className="filter-list">
                            {filters?.fabrics?.map((fabric: any, index: number) => {
                                return (
                                    <li key={index} className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                        <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={(e: any) => { addFilter(e, fabric.projectname, "projectName"); setFilterOpen(0) }}></div>
                                        <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${isItemChecked(fabric.projectname, "projectName") ? 'bg-warmcharcoal' : ''}`}>
                                            <Tick className={`${isItemChecked(fabric.projectname, "projectName") ? '' : 'hidden'} relative `} />
                                        </span>
                                        <span className="relative -top-[5px]">{fabric.projectname}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="col-span-3 md:col-span-2">
                    <p className="cursor-pointer" onClick={() => filterOpen === 3 ? setFilterOpen(0) : setFilterOpen(3)}>Size</p>
                    <div className={(filterOpen === 3 ? "block" : "hidden") + " bg-lightwarmgrey pt-4 px-4 md:px-[50px] absolute left-0 right-0 z-[1] mt-4 pb-8 min-h-[90dvh] md:min-h-[350px]"}>
                        <Close onClick={() => {setFilterOpen(-1)}} className="absolute z-[1002] right-4 lg:right-[50px] top-4 lg:top-6 stroke-warmcharcoal cursor-pointer hover:opacity-60"/>
                        <p className="underline underline-offset-4 mb-8 cursor-pointer hover:opacity-60" onClick={() => clearAllFilters()}>Clear all</p>
                        <ul className="filter-list">
                            {filters?.sizes?.map((size: any, index: number) => {
                                return (
                                    <li key={index} className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                        <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={(e: any) => { addFilter(e, size.first_word, "option2"); setFilterOpen(0) }}></div>
                                        <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${isItemChecked(size.first_word, "option2") ? 'bg-warmcharcoal' : ''}`}>
                                            <Tick className={`${isItemChecked(size.first_word, "option2") ? '' : 'hidden'} relative `} />
                                        </span>
                                        <span className="relative -top-[5px]">{size.first_word}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="col-span-3 md:col-span-2">
                    {/* <p className="cursor-pointer" onClick={() => filterOpen === 4 ? setFilterOpen(0) : setFilterOpen(4)}>Colour</p>
                    <div className={(filterOpen === 4 ? "block" : "hidden") + " bg-lightwarmgrey pt-4 px-4 md:px-[50px] absolute left-0 right-0 z-[1] mt-4 pb-8 min-h-[90dvh] md:min-h-[350px]"}>
                        <p className="underline underline-offset-4 mb-8 cursor-pointer hover:opacity-60" onClick={() => clearAllFilters()}>Clear all</p>
                        <ul className="filter-list">
                            {filters?.colours?.map((colour: any, index: number) => {
                                return (
                                    <li key={index} className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                        <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={(e: any) => { addFilter(e, colour.first_word, "option1"); setFilterOpen(0) }}></div>
                                        <span style={{backgroundColor: stc(colour.first_word)}} className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] rounded-full ${isItemChecked(colour.first_word, "option1") ? '' : ''}`}>
                                            <Tick className={`${isItemChecked(colour.first_word, "option1") ? '' : 'hidden'} relative `} />
                                        </span>
                                        <span className="relative -top-[5px]">{colour.first_word}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div> */}
                </div>
                <div className="hidden md:flex md:col-span-3 mt-4 md:mt-0 justify-end">
                    <p className="font-medium md:text-right">{results} Products</p>
                </div>
            </div>
            <div className="w-full px-4 lg:px-[50px] pb-8 filter-bar">
                <ul className="w-full">
                    {Object.keys(filterSet).map((filterType) => (
                        filterSet[filterType].map((item: any, index: any) => {
                           return (
                            <li className="list-none inline-block pr-6 hover:opacity-60 cursor-pointer capitalize" onClick={() => removeItem(item, filterType)} key={index}>
                                <Close className="stroke-warmcharcoal inline-block mr-2 ml-6 first:ml-0" />{item}
                            </li>
                            )
                        }
                        )
                    ))}
                </ul>
            </div>
        </section>
        <div className="duration-300" ref={resultsRef}></div>
    </>
    );
};

export default Filters;
