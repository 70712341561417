'use client';

import { useEffect, useRef, useState } from 'react';
import CartBenefits from '@/assets/icons/cart-benefits.svg';
import CartBenefitsMobile from '@/assets/icons/cart-benefits-mobile.svg'

interface ProductDetailProps {
    product?: any | null;
    description?: any | null;
}

const ProductDetail: React.FC<ProductDetailProps> = ({ product, description }) => {
    const plusSVG = `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="7" y1="2.18557e-08" x2="7" y2="13" stroke="#383635"/>
                        <line x1="13" y1="7" x2="-4.37114e-08" y2="7" stroke="#383635"/>
                    </svg>`;

    const minusSVG = `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="13" y1="7" x2="-4.37114e-08" y2="7" stroke="#383635"/>
                    </svg>`;

    const removeAttributesFromHTML = (htmlString: string) => {
        if (typeof window === 'undefined') return htmlString; // Check if running in the browser

        try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');

            const elements = doc.querySelectorAll('*');
            elements.forEach(element => {
                while (element.attributes.length > 0) {
                    element.removeAttribute(element.attributes[0].name);
                }
            });

            const emElements = doc.querySelectorAll('p > em');
            if (emElements.length === 0) {
                // No <p><em> elements found
                return htmlString;
            }

            emElements.forEach((em: any) => {
                const parentP = em.parentElement as HTMLElement;
                let nextElement = parentP.nextElementSibling;
                const contentElements: HTMLElement[] = [];

                while (nextElement && !(nextElement.tagName === 'P' && nextElement.querySelector('em'))) {
                    contentElements.push(nextElement as HTMLElement);
                    nextElement = nextElement.nextElementSibling;
                }

                // Wrap the em and following content in a div for accordion
                const accordionHeader = document.createElement('div');
                accordionHeader.className = 'accordion-header';
                accordionHeader.innerHTML = `${em.outerHTML} <span class="toggle-sign">${plusSVG}</span>`;

                const accordionContent = document.createElement('div');
                accordionContent.className = 'accordion-content';
                contentElements.forEach(element => {
                    accordionContent.appendChild(element);
                });

                parentP.innerHTML = '';
                parentP.appendChild(accordionHeader);
                parentP.appendChild(accordionContent);
            });

            return doc.body.innerHTML;
        } catch (error) {
            // console.log('Error parsing HTML:', error);
            return htmlString; // Return the original HTML string in case of an error
        }
    };

    const [cleanHTML, setCleanHTML] = useState('');

    useEffect(() => {
        if (description?.productdata?.description) {
            const cleanedHTML = removeAttributesFromHTML(description.productdata.description);
            setCleanHTML(cleanedHTML);
        }
    }, [product, description]);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!description?.productdata?.description) return;

        const container = ref.current;
        if (container) {
            const headers = container.querySelectorAll('.accordion-header');

            headers.forEach((header: any) => {
                const toggleSiblingVisibility = () => {
                    const content = header.nextElementSibling as HTMLElement;
                    const toggleSign = header.querySelector('.toggle-sign') as HTMLElement;

                    if (content.style.display === 'block') {
                        content.style.display = 'none';
                        toggleSign.innerHTML = plusSVG;
                    } else {
                        content.style.display = 'block';
                        toggleSign.innerHTML = minusSVG;
                    }
                };

                header.addEventListener('click', toggleSiblingVisibility);

                // Initially hide all contents
                const content = header.nextElementSibling as HTMLElement;
                if (content) {
                    content.style.display = 'none';
                }
            });
        }
    }, [cleanHTML, plusSVG, minusSVG]);

    return (
        <section className="w-screen md:w-full px-4 md:pl-0 md:pr-6 lg:pr-[54px]">
            {/* <h2 className="font-sangbleu font-medium text-[21px] leading-[32px]">{product?.productdata?.name}</h2> */}
            <h4 className="font-norma font-semibold text-[14px] leading-[18px] pt-3 border-t-[1px] border-warmcharcoal">Details</h4>
            <div ref={ref} className="detail prose max-w-none relative font-normal font-norma text-[14px] leading-[21px] pt-5 prose-em:text-[14px] prose-em:leading-[18px] prose-em:not-italic prose-p:w-full prose-em:w-full prose-em:font-semibold prose-em:border-t-[1px] prose-em:border-t-warmcharcoal prose-em:cursor-pointer prose-em:pt-3 prose-table:mb-4 prose-em:inline-block prose-li:ml-0 prose-li:pl-0 prose-ul:ml-0 prose-ul:pl-4 marker:text-warmcharcoal"
                dangerouslySetInnerHTML={{ __html: cleanHTML }} />
                <CartBenefits className="mx-auto mt-8 hidden md:inline-block max-w-[600px] w-full" />
                <CartBenefitsMobile className="block md:hidden mx-auto mt-8  max-w-[280px] w-full mb-24" />
        </section>
    );
};

export default ProductDetail;
