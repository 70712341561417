'use client'
import { useState, Fragment} from 'react'
import { useSession } from 'next-auth/react';
import { getCountryData } from '@/components/getCountryData'
import { useLocalStorage } from 'react-use';
import _ from 'lodash';

interface VoucherSetProps {
    vouchers?: any | null
    lang?:any | null
}

const VoucherSet:React.FC<VoucherSetProps>  = ({vouchers, lang}) => {

    const [ voucher, setVoucher ] = useState([]) as any
    const [ voucherType, setVoucherType ] = useState('Digital Card')
    const { data: session, update } = useSession() as any;
    const { currency, priceColumn } = getCountryData(lang);
    const [ selectedProductIds, setSelectedProductIds ] = useState<{ id: number, quantity: number }[]>([]) as any;
    const [ savedCart, setSavedCart ] = useLocalStorage('savedCart') as any;
    

    const updateCart = async () => {
        // console.log("updateCart called"); // Log for debugging
        if (session?.user?.uuid && selectedProductIds.length > 0) {
            // console.log("Updating cart for user UUID:", session.user.uuid); // Log for debugging
            const setCart = await fetch('/api/cart', { 
                method: 'post', 
                body: JSON.stringify({
                    ids: vouchers[voucher].id,
                    uuid: session.user.uuid
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const cartResponse = await setCart.json();
            document.getElementById("cartPanel")?.classList.add("left-0","right-0","md:left-auto");
        } else {
            const cartArray = savedCart?.length > 0 ? [...savedCart] : [] as any;

            selectedProductIds?.map((item: any) => {
                cartArray.push({ id: item.id, quantity: item.quantity });
            });

            const aggregatedCart = _.reduce(cartArray, (result: any, item: any) => {
                const existingItem = _.find(result, { id: item.id });
                if (existingItem) {
                    existingItem.quantity += item.quantity;
                } else {
                    result.push(_.clone(item));
                }
                return result;
            }, []);

            // console.log("Saving cart to local storage:", aggregatedCart); // Log for debugging
            setSavedCart(aggregatedCart);
            document.getElementById("cartPanel")?.classList.add("left-0","right-0","md:left-auto");
        }
    };

    return (
        <section className="font-norma px-4 md:px-8 lg:px-[50px] -mt-24 mb-24 block" style={{display: 'block !important'}}>
            <p className="font-medium text-[14px] text-warmcharcoal pb-6">Choose your voucher type</p>
            <div className="grid grid-cols-12">
                <div className="col-span-12 md:col-span-8">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-6 md:col-span-3">
                            <button type="button" className={(voucherType === 'Digital Card' ? "text-white bg-warmcharcoal" : "text-warmcharcoal bg-white") + " h-[42px] text-[16px] w-full text-center"} onClick={()=>setVoucherType('Digital Card')}>Digital gift voucher</button>
                        </div>
                        <div className="col-span-6 md:col-span-3">
                            <button type="button" className={(voucherType === 'Physical Card' ? "text-white bg-warmcharcoal" : "text-warmcharcoal bg-white") + " h-[42px] text-[16px] w-full text-center"} onClick={()=>setVoucherType('Physical Card')}>Physical gift voucher</button>
                        </div>
                    </div>
                </div>
            </div>
            <p className="font-normal text-[14px] text-warmcharcoal pt-6 pb-12">Note: digital gift cards will be processed within 48hrs of purchase</p>
            <p className="font-medium text-[14px] text-warmcharcoal pb-6">Choose your value(s) you can order multiple values and adjust quantities in the cart</p>
            <div className="grid grid-cols-12">
                <div className="col-span-12 md:col-span-8">
                    <div className="grid grid-cols-12 gap-6">
                        {vouchers?.map((item: any, index: number)=>{
                            if(item.productdata?.productOptions[0]?.option1 === voucherType) {
                                return (
                                    <Fragment key={index}>
                                {item.productdata?.productOptions?.map((value: any, vIndex: number)=>{
                                    return (
                                        <div className="col-span-6 md:col-span-3" key={vIndex}>
                                            <button
                                                type="button"
                                                className={
                                                    (_.includes(voucher, value.id)
                                                        ? "text-offwhite font-medium bg-warmcharcoal"
                                                        : "text-warmcharcoal bg-white") +
                                                    " h-[42px] text-[16px] w-full text-center"
                                                }
                                                onClick={() => {
                                                    if (_.some(selectedProductIds, { id: value.id })) {
                                                        // Remove the voucher from selectedProductIds and voucher
                                                        setSelectedProductIds(selectedProductIds.filter((item: any) => item.id !== value.id));
                                                        setVoucher(_.without(voucher, value.id));
                                                    } else {
                                                        // Add the voucher to selectedProductIds and voucher
                                                        setSelectedProductIds([...selectedProductIds, { id: value.id, quantity: 1 }]);
                                                        setVoucher([...voucher, value.id]);
                                                    }
                                                }}
                                            >
                                                {currency} {value.priceColumns[priceColumn]}
                                            </button>
                                        </div>
                                    )
                                })
                            }
                            </Fragment>)}
                        })}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 mt-12">
                <div className="col-span-12 md:col-span-8">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-6 md:col-span-6">
                            <button type="button" className="bg-warmcharcoal h-[42px] font-medium text-[14px] text-offwhite w-full text-center" onClick={()=>updateCart()}>Add to Cart</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VoucherSet
