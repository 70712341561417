'use client'
import * as React from 'react';
import { useSession } from 'next-auth/react';
import { getCountryData } from '@/components/getCountryData';
import { CartEvents } from '@/components'
import { useWindowScroll } from 'react-use'
import NextLink from 'next/link'
import { useLocalStorage } from 'react-use';
import _ from 'lodash';

interface ProductAddFilterProps {
    current?: any | null;
    products?: any | null;
    id?: any | null;
    lang?: any | null;
    rules?: any | null;
    paths?: any | null
}

const ProductAddFilter: React.FC<ProductAddFilterProps> = ({ current, products, id, lang, rules, paths }) => {
    const { data: session } = useSession() as any;
    const { currency, priceColumn } = getCountryData(lang);
    const [selectedProductIds, setSelectedProductIds] = React.useState<{ id: number, quantity: number }[]>([]);
    const [savedCart, setSavedCart] = useLocalStorage('savedCart', []) as any;
    const [totalPrice, setTotalPrice] = React.useState<{ [key: number]: number }>({});
    const [beforeDiscountPrice, setBeforeDiscountPrice] = React.useState<{ [key: number]: number }>({});
    const [discountLabel, setDiscountLabel] = React.useState<string>("");
    const [cartTrigger, setCartTrigger] = React.useState(null) as any;

    
    const { x,y } = useWindowScroll()

    const findBestRuleForProduct = (product: any, rules: any) => {
        let bestRule: any = null;
        let highestAmount = 0;
    
        rules.forEach((rule: any) => {
            rule.rules_json?.products.forEach((rulesProducts: any) => {
                if (rulesProducts.category?.id && product.categoryIdArray?.includes(rulesProducts.category.id)) {
                    // console.log('cat: ', rule.rules_json.rules.amount, rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.projectname && product.projectName === rulesProducts.projectname) {
                    // console.log('proj: ', rule.rules_json.rules.amount, rulesProducts.projectname);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.productdata && rulesProducts.productdata.id === product.id) {
                    // console.log('prods: ', rule.rules_json.rules.amount, rulesProducts.productdata.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts === "all") {
                    // console.log('all: ', rule.rules_json.rules.amount, rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
            });
        });
    
        return bestRule?.rules_json?.rules;
    };
    
    React.useEffect(() => {
        if (current && current.length > 0) {
            const firstProduct = current[0].productdata.productOptions[0];
            if (firstProduct) {
                
                const applicablePromotion = findBestRuleForProduct(current[0].productdata, rules) as any

                setSelectedProductIds([{ id: firstProduct.id, quantity: 1 }]);

                if (applicablePromotion) {
                    let discounted = applicablePromotion.amount;
                    discounted = applicablePromotion.type === "percentage" ? discounted / 100 : discounted;

                    const discountPrice = firstProduct.priceColumns[priceColumn] - firstProduct.priceColumns[priceColumn] * discounted;
                    const discountLabelType = applicablePromotion.type === "percentage" ? `${applicablePromotion.amount}% off` : `${currency} ${applicablePromotion.amount} off`;

                    setDiscountLabel(discountLabelType);
                    setTotalPrice({ [firstProduct.id]: discountPrice });
                    setBeforeDiscountPrice({ [firstProduct.id]: firstProduct.priceColumns[priceColumn] });
                } else {
                    setTotalPrice({ [firstProduct.id]: firstProduct.priceColumns[priceColumn] });
                    setBeforeDiscountPrice({ [firstProduct.id]: firstProduct.priceColumns[priceColumn] });
                }

                const firstLiElement = document.querySelector('.product-options-list li');

                if (firstLiElement) {
                    firstLiElement.classList.add('bg-warmcharcoal', 'text-offwhite');
                    firstLiElement.classList.remove('text-warmcharcoal');
                }
            }
        }
    }, [current, rules, priceColumn, currency]);

    const saveToDB = async (uuid: string, aggregatedCart: any[]) => {
        try {
            const response = await fetch('/api/cart', {
                method: 'POST',
                body: JSON.stringify({ cartItems: aggregatedCart, uuid }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to save cart to database');
            }

        } catch (error) {
            // console.log('Error saving cart to database:', error);
        }
        
        
    };

    const updateCart = async () => {

        const savedCartString = localStorage.getItem('savedCart');
        const cartArray = savedCartString ? JSON.parse(savedCartString) : [];
        const uuid = session?.user?.uuid
        // console.log(uuid && (cartArray?.length > 0 || !cartArray))
        if(uuid && (cartArray?.length < 1 || !cartArray)) {
            const updateKlaviyo = await fetch('/api/klaviyo/cart/cleared?uuid='+ uuid)
            const updateKlaviyoResponse = await updateKlaviyo.json()
        }

        if(uuid && (cartArray?.length > 0)) {
            const updateKlaviyo = await fetch('/api/klaviyo/cart', { method: "post", body: JSON.stringify({ uuid, lang })}) 
            const updateKlaviyoResponse = await updateKlaviyo.json();
        }

        selectedProductIds?.forEach((item) => {
            cartArray.push({ id: item.id, quantity: item.quantity });
        });

        const aggregatedCart = _.reduce(cartArray, (result: any, item: any) => {
            const existingItem = _.find(result, { id: item.id }) as any;
            if (existingItem) {
                existingItem.quantity += item.quantity;
            } else {
                result.push(_.clone(item));
            }
            return result;
        }, []);

        setSavedCart(aggregatedCart);

        if (session?.user?.uuid) {
            await saveToDB(session.user.uuid, aggregatedCart);
        }
        document.body.style.overflow="hidden"
        setCartTrigger(true);
    };

    React.useEffect(() => {
        if (cartTrigger === true) {
            // console.log('cartTrigger: ', savedCart);
            document.querySelector("#cartPanel")?.classList.add("left-0", "right-0", "md:left-auto");
            document.querySelector("#cartDetail")?.classList.add("update-cart");
            setCartTrigger(false);
        }
    }, [cartTrigger]);

    const updateTotal = (
        setId: number,
        price: string,
        id: number,
        item: HTMLElement,
        productOptions: any[]
    ) => {
        item.classList.toggle("bg-warmcharcoal");
        item.classList.toggle("text-offwhite");

        if (!item.classList.contains("bg-warmcharcoal")) {
            item.classList.add("text-warmcharcoal");
        } else {
            item.classList.remove("text-warmcharcoal");
        }

        const siblings = Array.from(item.parentElement?.children || []).filter((child) => child !== item);
        siblings?.forEach((sibling) => {
            sibling.classList.remove("bg-warmcharcoal", "text-offwhite");
            sibling.classList.add("text-warmcharcoal");
        });

        const itemPrice = parseFloat(price);
        const updatedTotalPrice = { ...totalPrice };
        const updatedBeforeDiscountPrice = { ...beforeDiscountPrice };

        const selectedProductOption = productOptions.find(option => option.id === id);
        if (!selectedProductOption) {
            // console.log(`Product option with id ${id} not found.`);
            return;
        }

        const productId = selectedProductOption.productId;

        const selectedProductData = products.find((item: any) => {
            return item.productdata.id === productId;
        });

        if (!item.classList.contains("bg-warmcharcoal")) {
            delete updatedTotalPrice[id];
            delete updatedBeforeDiscountPrice[id];
            setSelectedProductIds(prev => prev.filter(product => product.id !== id));
        } else {
            const productOptionIds = productOptions.map(option => option?.id);
            let discountPrice = itemPrice;

            const applicablePromotion = findBestRuleForProduct(selectedProductData?.productdata, rules) as any

            if (applicablePromotion) {
                let discounted = applicablePromotion.amount;
                discounted = applicablePromotion.type === "percentage" ? discounted / 100 : discounted;
                discountPrice = itemPrice - itemPrice * discounted;
                const discountLabelType = applicablePromotion.type === "percentage" ? `${applicablePromotion.amount}% off` : `${currency} ${applicablePromotion.amount} off`;
                setDiscountLabel(discountLabelType);
            } else {
                discountPrice = itemPrice;
                setDiscountLabel("");
            }

            productOptionIds?.forEach(optionId => {
                if (optionId !== id) {
                    delete updatedTotalPrice[optionId];
                    delete updatedBeforeDiscountPrice[optionId];
                }
            });

            setSelectedProductIds(prev => {
                const newSelectedIds = prev.filter(product => !productOptionIds.includes(product.id));
                const existingProduct = newSelectedIds.find(product => product.id === id);

                if (existingProduct) {
                    existingProduct.quantity += 1;
                    return [...newSelectedIds];
                } else {
                    return [...newSelectedIds, { id, quantity: 1 }];
                }
            });

            updatedTotalPrice[id] = discountPrice;
            updatedBeforeDiscountPrice[id] = itemPrice;
        }

        setTotalPrice(updatedTotalPrice);
        setBeforeDiscountPrice(updatedBeforeDiscountPrice);
    };

    const sortedProducts = _.orderBy(products, item => {
        const retailValues = item.productdata.productOptions.map((option: any) => option?.priceColumns[priceColumn]);
        return _.max(retailValues);
    }, ['desc']);

    let prodCount = 0;

    const moveToOptions = (e: any) => {
        const colours = document.getElementById("productColours")
        if (colours) {
            colours.scrollIntoView({
                behavior: 'smooth'
            });
            if(y < e.offsetTop) {
                e.classList.add("hidden")
            } 
        }
        return null;
    }

    React.useEffect(() => {
        const button = document.getElementById("chooseOptions"); // Replace with the actual button ID
        const colours = document.getElementById("productColours");

        if (colours && button) {
            const coloursTop = colours.getBoundingClientRect().top + y;

            // Hide the button when the scroll position is past productColours
            if (y >= coloursTop) {
                button.classList.add("hidden");
            } else {
                button.classList.remove("hidden");
            }
        }
    }, [y]); 

    return (
        <>    
            <button type="button" id="chooseOptions" className="md:hidden z-10 fixed bottom-0 left-0 right-0 bg-offwhite text-offwhite text-[14px] font-norma " onClick={(e: any)=>moveToOptions(e.target)}><span className="bg-warmcharcoal m-4 mb-8 h-[40px] flex justify-center items-center">Choose size and colour options</span></button>     
            <div className="w-screen lg:w-full px-4 lg:pl-0 lg:pr-[54px]">
                {current?.map((item: any, index: number) => {
                    // console.log(priceColumn)
                    if (index === 0) {
                        const retailValues = _.flatMap(item, product => item.productdata.productOptions
                            .filter((option: any) => option !== null && option !== undefined)
                            .map((option: any) => option?.priceColumns[priceColumn]));
                        const nonZeroRetailValues = _.filter(retailValues, value => value !== 0);
                        const lowestRetail = _.min(nonZeroRetailValues);
                        const sortedOptions = _.orderBy(item.productdata.productOptions, ['priceColumns?.[priceColumn]'], ['desc']);
                        return (
                            <div className="grid grid-cols-12 py-8 pb-5 border-t-[1px] border-t-warmcharcoal" key={index}>
                                <div className="col-span-12 md:col-span-3 pr-5 mb-4">
                                    <h4 className="font-norma font-medium text-[14px] leading-[18px]">Size</h4>
                                    <p className="opacity-70 text-[12px] leading-[18px] font-norma">{sortedOptions.length > 1 ? "From" : "Price"} ${lowestRetail?.toFixed(2)}</p>
                                </div>
                                <div className="col-span-12 md:col-span-9">
                                    <ul className="product-options-list">
                                        {sortedOptions.map((option, oIndex) => {
                                            if(option?.id) {
                                                return (
                                                    <li className="inline-block mr-2 lg:mx-1 mb-2 border-[1px] border-warmcharcoal cursor-pointer px-3 min-w-[30%] md:min-w-[23%] h-[24px] text-[12px] text-center" key={oIndex} onClick={(e) => updateTotal(index, option.priceColumns[priceColumn], option.id, e.currentTarget, item.productdata.productOptions)}>
                                                        <span className="relative -top-[2px] inline-block">{option?.option2 || "Standard"}</span>
                                                    </li>
                                                )
                                            }
                                        }
                                        )}
                                    </ul>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
                
                {sortedProducts.map((item: any, index: number) => {
                    const retailValues = _.flatMap(item, product => item.productdata.productOptions.map((option: any) => option?.priceColumns[priceColumn]));
                    const nonZeroRetailValues = _.filter(retailValues, value => value !== 0);
                    const lowestRetail = _.min(nonZeroRetailValues);
                    const sortedOptions = _.orderBy(item.productdata.productOptions, ['priceColumns?.[priceColumn]'], ['desc']);
                    if (item.productdata.id !== current[0].productdata.id && lowestRetail) {
                        prodCount++;
                        return (
                            <div key={index}>
                            {prodCount ===1 ?
                                <div className="pt-12 pb-3 border-b-[1px] border-b-warmcharcoal">
                                    <h4 className="mb-2 font-norma font-medium text-[14px] leading-[18px]">Add to your set</h4>
                                </div>
                            :<></>}
                            <div className="grid grid-cols-12 py-4" >
                                <div className="col-span-12 md:col-span-3 pr-5 pb-5">
                                    <h4 className="font-norma font-medium text-[14px] leading-[18px]"><NextLink href={(`/${lang}/${item.productdata.productType}/${item.productdata.productSubtype}/${item.productdata.name}/${paths.color}`)?.toLowerCase().replace(/ /g, "-").replace(/---/g, "---").replace(/-&-/g, "-+-")}>{item.productdata.name}</NextLink></h4>
                                    <p className="opacity-70 text-[12px] leading-[18px] font-norma">{sortedOptions.length > 1 ? "From" : "Price"} ${lowestRetail?.toFixed(2)}</p>
                                </div>
                                <div className="col-span-12 md:col-span-9">
                                    <ul>
                                        {sortedOptions.map((option, oIndex) => {
                                            if(option?.id) {
                                            return (
                                                <li className="inline-block mr-2 lg:mx-1 mb-2 border-[1px] border-warmcharcoal cursor-pointer px-3 min-w-[30%] md:min-w-[23%] h-[24px] text-[12px] text-center" key={oIndex} onClick={(e) => updateTotal(index, option.priceColumns[priceColumn], option.id, e.currentTarget, item.productdata.productOptions)}>
                                                    <span className="relative -top-[2px] inline-block font-norma">{option?.option2 || "Standard"}</span>
                                                </li>
                                            )
                                        }
                                        })}
                                    </ul>
                                </div>
                            </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
            <div className="w-screen px-4 lg:px-0 lg:w-auto lg:mx-0 text-center text-[32px] mb-20 mt-1 lg:mr-[54px]">
            
                <div className="border-t-[1px] border-t-warmcharcoal py-4"></div>
                {!_.isEqual(totalPrice, beforeDiscountPrice) ? (
                    <p className="font-norma text-[21px] leading-[32px] my-2 uppercase">
                        <span className="text-warmred line-through">{currency} {_.sum(Object.values(beforeDiscountPrice)).toFixed(2)}</span>
                        <span className="text-warmcharcoal inline-block ml-6 text-[21px]">{currency} {_.sum(Object.values(totalPrice)).toFixed(2)}</span>
                        {/* <span className="text-[12px] text-normal inline-block ml-2 text-warmred">{discountLabel}</span> */}
                    </p>
                ) : (
                    <p className="font-norma text-[21px] leading-[32px] my-2 uppercase">{currency} {_.sum(Object.values(totalPrice)).toFixed(2)}</p>
                )}
                
                <div className="bg-warmcharcoal h-[40px] w-full text-offwhite font-norma text-[14px] flex items-center justify-center my-4 cursor-pointer hover:bg-warmcharcoal/60" onClick={updateCart}>{Number(_.sum(Object.values(totalPrice)).toFixed(2)) > 0 ? "Add to cart" : "Nothing selected - show cart?"}</div>
                {/* <div className="mx-auto text-center py-4">
                    <square-placement
                        data-mpid="67b6373b-f869-425f-93a1-da6fb6a90531"
                        data-placement-id="88a526b6-152f-449b-a243-64adf95221cc"
                        data-page-type="product"
                        data-amount={_.sum(Object.values(totalPrice)).toFixed(2)}
                        data-currency="AUD" //{currency.replace("$", "")}
                        data-consumer-locale={lang}
                        data-is-eligible="true">
                    </square-placement>
                </div> */}
            </div>
            <CartEvents lang={lang} cartItems={savedCart} addToCart={cartTrigger} rules={rules} activeEvent={"add_to_cart"}/>
        </>
    );
}

export default ProductAddFilter;
