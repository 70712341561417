'use client'
import { useState } from 'react' 
import NextImage from 'next/image'
import _ from 'lodash'

interface TheLookTileProps {
    tileIndex?: any | null
    priceColumn?: any | null
    lookData?: any | null
    currency?: any | null
    productId?: any | null
    rules?: any | null
}

const TheLookTile:React.FC<TheLookTileProps>  = ({ tileIndex, priceColumn, lookData, currency, productId, rules}) => {

    // console.log(lookData)

    const [lookProd, setLookProd] = useState(false);
    const [results, setResults] = useState([]) as any;
    const [preview, setPreview] = useState(lookData ? lookData : null)


    const searchProducts = async (name: string) => {
        if(name.length >= 3) {
            const searchProducts = await fetch(`/api/promotions/products?prod=${name}`);
            const productsResult = await searchProducts.json();
            setResults(productsResult)
        }
    }

    const updateLook = async (tileIndex: number, id: number, productId: number, event: any) => {
        event.stopPropagation();  
        const response = await fetch(`/api/thelook?look=${productId}`);
        const the_look = await response.json();

        let newLook = the_look[0]?.the_look || [0, 0, 0, 0, 0, 0];
        newLook[tileIndex] = id;

        const pageUrl = window.document.location.pathname

        await fetch(`/api/thelook/update?look=[${newLook}]&prodId=${productId}&pageUrl=${pageUrl}`);

        const updateTile = await fetch(`/api/promotions/options?prodId=${id}`);
        const resultTile = await updateTile.json();
 
        lookData[tileIndex] = resultTile[0]

        setPreview(lookData);

        setLookProd(false)
    };

    function findBestRuleForProduct(product: any, rules: any) {
        let bestRule: any = null;
        let highestAmount = 0;
      
        rules.forEach((rule: any) => {
            rule.rules_json?.products.forEach((rulesProducts: any) => {
                if (rulesProducts.category?.id && product.categoryIdArray?.includes(rulesProducts.category.id)) {
                    // console.log('cat: ', rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.projectname && product.projectName === rulesProducts.projectname) {
                    // console.log('proj: ', rulesProducts.projectname);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.productdata && rulesProducts.productdata.id === product.id) {
                    // console.log('prods: ', rulesProducts.productdata.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts === "all") {
                    // console.log('all: ', rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
            });
        });
      
        return bestRule?.rules_json?.rules;
      };
    
    return (
        <>
        {!lookProd ?
            (<div  className="col-span-12 md:col-span-4 origin-center cursor-pointer duration-300 mb-16" onClick={() => {setLookProd(true); setResults([])}}>
                {preview[tileIndex]?.productdata?.images[0]?.link ? 
                    <NextImage src={preview[tileIndex]?.productdata?.images[0]?.link } className="w-full object-cover aspect-square" alt="" width={600} height={600}/>
                :
                <div className="w-full object-cover aspect-square bg-warmgrey flex items-center justify-center text-offwhite">+ Add Product</div>
                }
                <div className="grid grid-cols-12 py-6 h-[110px]">
                    <div className="col-span-12 md:col-span-6 lg:col-span-7 pr-8">
                    {preview[tileIndex]?.productdata?.name ? 
                        <p className="font-norma font-medium text-[16px] leading-[24px]">{preview[tileIndex]?.productdata?.name}</p>
                    : 
                        <p className="bg-warmgrey h-[30px] w-full"></p>
                    }
                    </div>
                    <div className="col-span-12 md:col-span-6 lg:col-span-5 md:flex md:justify-end">
                    {preview[tileIndex]?.productdata?.productOptions[0].priceColumns?.[priceColumn] ? 
                    <p className="font-norma font-normal text-[16px] leading-[24px]">From {currency} {(preview[tileIndex]?.productdata?.productOptions[0].priceColumns?.[priceColumn]).toFixed(2)}</p>
                    :
                    <p className="bg-warmgrey h-[30px] w-full max-w-[100px]"></p>
                    }
                    </div>
                </div>
                <button className="font-forma font-medium w-full py-[13px] text-offwhite text-[14px] h-[40px] leading-[14px] bg-warmcharcoal text-center">{preview[tileIndex]?.productdata?.name ? "Buy now" :""}</button>
            </div>)
            : 
            (<div  className="col-span-12 md:col-span-4 origin-center cursor-pointer duration-300 mb-16">
                <div className="mt-8 font-norma text-[12px] font-medium text-right underline underline-offset-4 mb-2 text-warmcharcoal" onClick={() => {setLookProd(false); setResults([])}}>Close</div>
                <div className="mt-8 font-norma text-[12px] font-medium text-left underline underline-offset-4 mb-2 text-warmcharcoal" onClick={(e) => { e.stopPropagation(); updateLook(tileIndex, 0, productId, e)}}>Remove</div>
                <input type="text" className=" h-[40px] border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="Enter product name or id" onChange={(e) => { e.preventDefault(); searchProducts(e.target.value); }}/>
                {results?.length > 0 &&
                <div>
                    {results.map((item:any, index: number) => (
                        <div className="grid grid-cols-12 bg-white my-2 p-2 cursor-pointer hover:opacity-100 opacity-80" onClick={(e) => { e.stopPropagation(); updateLook(tileIndex, item.productdata.id, productId, e); }} key={index}>
                            <div className="col-span-2">
                                <img src={item.productdata.images[0]?.link} className="rounded-md aspect-square w-full object-cover font-norma font-semibold text-[16px] text-warmcharcoal"/>
                            </div>
                            <div className="col-span-10 flex items-center pl-4">
                                <p className="font-norma text-[12px] text-warmcharcoal">{item.productdata.name}{item.productdata.productOptions[0].option1 ? " – " + item.productdata.productOptions[0].option1 : ""}</p>
                            </div>
                        </div>
                    ))}
                </div>}
            </div>)
        }
        </>
    )

}

export default TheLookTile
