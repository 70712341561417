import { createClient } from "@/prismicio";
import { HeaderMenuController } from '@/components'
import { sql } from "@vercel/postgres";

interface HeaderProps {
    uid?: any | null
    theme?: any | null
    lang?: any | null
    chrome?: any | null
}

async function getMenus(theme: any, uid: any, lang:any, chrome:any) {
    const client = createClient();
    const menus = await client.getSingle("menus");
    const megaMenus = await client.getAllByType("main_sections")

    const {rows} = await sql`WITH filtered_products AS (
                                    SELECT
                                        p.productdata->>'productType' AS productType,
                                        p.productdata->>'productSubtype' AS productSubtype
                                    FROM 
                                        public.products p
                                    JOIN
                                        public.stock s ON p.product_id = s.product_id
                                    WHERE 
                                        p.productdata->>'status' = 'Public'
                                        AND p.productdata->>'productType' <> ''
                                        AND p.productdata->>'productType' <> 'Unclassified'
                                        AND p.productdata->>'productSubtype' <> ''
                                        AND (
                                            CASE
                                                WHEN ${lang} = 'global' OR ${lang} = 'nz' THEN LOWER(s.branch_name) NOT LIKE '%au%'
                                                ELSE LOWER(s.branch_name) LIKE '%au%'
                                            END
                                        )
                                        AND s.stock_on_hand > 1
                                )
                                SELECT
                                    productType,
                                    jsonb_agg(DISTINCT productSubtype) AS productSubtypes
                                FROM 
                                    filtered_products
                                GROUP BY
                                    productType`;


    const {rows: rules} = await sql`
                    SELECT rules_json, details_json 
                        FROM public.promotions
                        WHERE status = true
                            AND (rules_json->'rules'->>'voucher' IS NULL OR rules_json->'rules'->>'voucher' = '')
                            AND to_timestamp(rules_json->'schedule'->>0, 'YYYY-MM-DD"T"HH24:MI:SS.MS"Z"') <= now()
                            AND to_timestamp(rules_json->'schedule'->>1, 'YYYY-MM-DD"T"HH24:MI:SS.MS"Z"') >= now();
    `;
    

    return (
        <HeaderMenuController chrome={chrome} megaMenus={megaMenus} menus={menus} theme={theme} lang={lang} uid={uid} menuOrder={menus.data.slices[0]?.items} cin7Menu={rows} subMenu={menus.data.header_navigation} defImage={menus.data.slices[0]?.primary.default_image} rules={rules}/>
    )
}


const Header:React.FC<HeaderProps> = ({theme, uid, lang, chrome}) => {
    return getMenus(theme, uid, lang, chrome)
}

export default Header
