'use client'
import { useState, useEffect, Fragment } from 'react'
import { useSession, signOut } from "next-auth/react"
import { PrismicNextLink, PrismicNextImage } from '@prismicio/next'
import SearchIcon from '@/assets/icons/search-icon.svg'
import RightArrow from '@/assets/icons/right-arrow.svg'
import CloseIcon from '@/assets/icons/close.svg'


interface MegaMenusProps {
    megaMenus?: any | null
    menuOrder?: any | null
    theme?: any | null
    subMenu?: any | null
    cin7Menu?: any | null
    defImage?: any | null
    collections?: any | null
    lang?: any | null
    menuOpen?: any | null
    setMenuOpen?: any | null
    megaOpen?: any | null
    setMegaOpen?: any | null
    setSavedCurrent?: any | null
    uid?: any | null
    setAccount?: any | null
    account?: any | null
    showCart?: any | null
    setShowCart?: any | null
    countryMenu?: any | null
    setCountryMenu?: any | null
    setShowSearch?: any | null
}

const MegaMenus:React.FC<MegaMenusProps> = ({ megaMenus, menuOrder, theme, subMenu, cin7Menu, defImage, lang, menuOpen, setMenuOpen, megaOpen, setMegaOpen, setSavedCurrent, uid, account, setAccount, showCart, setShowCart, countryMenu, setCountryMenu, setShowSearch}) => {

    const menuPrimary = [] as any

    menuOrder?.forEach((order: any) => {
        if(order.standard_link_text !== "") {
            menuPrimary.push({menu_data: null, link_data: { text: order.standard_link_text, link: order.standard_link }});
        }
        megaMenus?.forEach((menu: any) => {
            if(menu.id === order.mega_menu.id) {
                menuPrimary.push({menu_data: menu.data, link_data: null});
            }
        })
    });

    useEffect(() => {
        if (window.innerWidth >= 1280) {
            // setMegaOpen(1);
        }
    }, []);

    const { data: session, status } = useSession()

    const user = session?.user as any;

    return (
        <>
            {menuOpen === true ? 
            <div className="w-screen z-40 fixed bg-offwhite top-0 bottom-0 left-0 font-norma overflow-y-scroll lg:bottom-auto lg:h-[60px] lg:overflow-hidden">
                <div className="w-screen bg-offwhite pt-[60px] lg:pt-0 max-h-[720px]">
                    <CloseIcon className={"stroke-warmcharcoal absolute z-50 right-4 lg:right-[54px] top-4 md:top-[26px] cursor-pointer hover:opacity-60 "} onClick={()=>{setMenuOpen(false); document.body.style.overflow="visible"}}/>
                        <ul className="w-full m-0 p-0 flex justify-end px-5 pt-[4px] pb-[24px] border-b-[1px] border-warmcharcoal lg:hidden">
                            {subMenu?.map((subItem: any, index: number)=>{
                                if(subItem.link_text === "Login" && user?.uuid) {
                                    subItem.link_text = "Account"
                                }
                                return (
                                    <li  onClick={()=>{ subItem.link_text === "Login" || subItem.link_text === "Account" ?  setAccount(!account) : subItem.link_text === "Cart" ? setShowCart(!showCart) : document.body.style.overflow="visible"}} 
                                            className={(theme === true ? "text-offwhite" : "text-warmcharcoal ") + "inline-block font-norma text-[14px] mr-[20px]"} key={index}><PrismicNextLink field={subItem.link}>{subItem.link_text}</PrismicNextLink></li>
                                )
                            })}
                            <li className="text-[14px] leading-[18px] font-medium inline-block"><SearchIcon className="relative top-[4px] w-[14px] stroke-warmcharcoal" onClick={()=>{setShowSearch(true); document.body.style.overflow="hidden"}}/></li>
                        </ul>
                        <ul className="p-5 pt-[70px] md:pt-[22px] lg:pl-[54px] w-full h-[64px] relative z-10 md:mt-8 lg:mt-0">
                            {menuPrimary.map((primaryItem: any, index: any)=>{
                                if(primaryItem.menu_data?.section_name) return (
                                    <li className={"text-[14px] leading-[18px] font-medium lg:inline-block mr-[50px] cursor-pointer pb-[31px] lg:pb-0 hover:underline hover:underline-offset-4 "+ (index === megaOpen ? "underline underline-offset-4" : "")} key={index} onClick={()=>{setSavedCurrent(index); setMegaOpen(index)}}>{primaryItem.menu_data.section_name}</li>
                                ) 
                                if(primaryItem.link_data?.text) return (
                                    <li className="text-[14px] leading-[18px] font-medium lg:inline-block mr-[50px] cursor-pointer pb-[31px] lg:pb-0 hover:underline hover:underline-offset-4 " key={index}><a href={primaryItem.link_data.link?.uid ? "/"+lang+"/"+primaryItem.link_data.link.uid : primaryItem.link_data.link?.url}>{primaryItem.link_data.text}</a></li>
                                )
                            })}
                            <li className="hidden text-[14px] leading-[18px] font-medium lg:inline-block mr-[50px]"><SearchIcon className="relative top-[6px] w-[14px]" onClick={()=>{setShowSearch(true); document.body.style.overflow="hidden"}}/></li>
                        </ul>
                        {/* <div className="hidden md:block lg:hidden absolute top-[70px] right-0 z-10"><PrismicNextImage field={defImage} fallbackAlt="" className="w-[75vw] object-cover"/></div> */}
                        {/* <div className="hidden md:block lg:hidden absolute top-[64vh]  bg-gradient-to-b from-warmcharcoal/10 to-offwhite h-[10px] w-screen z-10 left-0 border-t-warmcharcoal/20 border-t-[1px]"></div> */}
                        {/* {menuPrimary.map((item: any, index: number)=>{
                            if(index === megaOpen && item.menu_data) {
                                return (
                                        <div className={"absolute left-0 top-[160px] bg-offwhite md:top-0 lg:top-[60px] right-0 lg:left-0  z-20 min-h-screen md:min-h-0 pb-12 md:pb-0 hidden lg:block"} key={index}>
                                            <div className="md:grid grid-cols-12 max-h-[720px] w-full" key={index}>
                                                <div className={(item.menu_data.columns !== "2" ? "col-span-12 md:col-span-4 lg:col-span-2" : " col-span-12 md:col-span-6 lg:col-span-4") + " px-5 mt-7 lg:pl-[54px] text-[14px] leading-[18px]"}>
                                                    <div className="mt-9 md:mt-0 lg:hidden cursor-pointer mb-12" onClick={()=>{setMegaOpen(-1); setSavedCurrent(1)}}>
                                                        <RightArrow className="stroke-warmcharcoal"/>
                                                        <h2 className="mt-[15px] font-semibold text-[14px] leading-[18px] mb-[30px] md:mb-[14px]">{item.menu_data.section_name}</h2>
                                                    </div>
                                                    {item.menu_data.links?.length > 0 ?
                                                    <div className="md:grid grid-cols-12 gap-x-8 w-full">
                                                        <div className="col-span-12 md:col-span-6">
                                                            <ul>
                                                            {item.menu_data.links?.map((menu: any, mindex: number)=>{ 
                                                                if(menu.column !== "2") return (
                                                                    <li className={"block py-2 text-[14px] leading-[18px] cursor-pointer hover:underline hover:underline-offset-4 " +(menu.is_heading == true ? " font-semibold mt-3 " : " font-norma ") +(menu.link.uid === uid ? " underline underline-offset-4" : "") } key={mindex}><a href={menu.link.uid ? "/"+lang+"/"+menu.link.uid : menu.link?.url}>{menu.link_text}</a></li>
                                                                )
                                                            })}
                                                            </ul>
                                                        </div>
                                                        <div className="col-span-12 md:col-span-6">
                                                            <ul>
                                                            {item.menu_data.links?.map((menu: any, mindex: number)=>{
                                                                if(menu.column === "2") return (
                                                                    <li className={"block py-2 text-[14px] leading-[18px] cursor-pointer md:pr-8 xxl:pr-0 hover:underline hover:underline-offset-4 " +(menu.is_heading == true ? " font-semibold mt-3 " : " font-norma ") +(menu.link.uid === uid ? " underline underline-offset-4" : "")} key={mindex}><a href={menu.link.uid ? "/"+lang+"/"+menu.link.uid : menu.link?.url}>{menu.link_text}</a></li>
                                                                )
                                                            })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    : <></>}
                                                    {cin7Menu?.length > 0 ?
                                                    <div className="md:grid grid-cols-12 gap-x-8 w-full">
                                                        <div className="col-span-12 md:col-span-6">
                                                            <ul>
                                                            {cin7Menu?.map((c7item: any, c7index: number)=>{
                                                                if(c7item.producttype===item.menu_data.section_name)
                                                                return (
                                                                    <Fragment key={c7index}>
                                                                    <li className={"block py-2 text-[14px] leading-[18px] cursor-pointer md:pr-8 xxl:pr-0 font-semibold mt-3 hover:underline hover:underline-offset-4 " + (c7item.producttype?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---") === uid ? " underline underline-offset-4" : "")}><a href={"/"+lang+"/"+c7item.producttype?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---")}>{c7item.producttype.replace("&", "+")}</a></li>
                                                                    {item.menu_data.section_name === "Living" ? <li className={"block py-2 text-[14px] leading-[18px] cursor-pointer md:pr-8 xxl:pr-0 font-normal mt-3 hover:underline hover:underline-offset-4"}><a  href={"/"+lang+"/living/blankets---throws"}>Blankets + Throws</a></li>:<></>}
                                                                    {c7item.productsubtypes?.map((sub: any, mindex: number)=>{
                                                                        return (
                                                                            <li className={"block py-2 text-[14px] leading-[18px] cursor-pointer md:pr-8 xxl:pr-0 font-normal mt-3 hover:underline hover:underline-offset-4 " + (sub?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---") === uid ? " underline underline-offset-4" : "")} key={mindex}><a  href={"/"+lang+"/"+c7item.producttype?.trim().toLowerCase().replace(/ /g, "-")+"/"+sub?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---")}>{sub.replace("&", "+")}</a></li>
                                                                        )
                                                                    })}
                                                                    </Fragment>
                                                                )
                                                            })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    :<></>}
                                                    {item.menu_data.section_name === "Collections" ?
                                                        <>
                                                        <h2 className={"block py-2 text-[14px] leading-[18px] md:pr-8 xxl:pr-0 font-semibold mt-5 cursor-pointer hover:opacity-60" + (uid=== "collections" ? "underline underline-offset-8" : "")}><a href={`/${lang}/collections`}>{item.menu_data.section_name }</a></h2>
                                                                                
                                                        <div className="col-span-12 md:col-span-6">
                                                            <div className="grid grid-cols-12 w-full">
                                                                <div className="col-span-12 md:col-span-6">
                                                                {item.menu_data?.slices?.map((slice: any, col_index: number)=>{
                                                                    if(slice.slice_type === "collection_set" && slice.primary.column < 2) {
                                                                        return (
                                                                            <ul className="mb-[19px]" key={col_index}>
                                                                                <li className={"block text-[14px] pt-3 leading-[18px] cursor-pointer md:pr-8 xxl:pr-0 font-semibold mt-3 hover:underline hover:underline-offset-4 " + (slice.primary.collection_section?.uid === uid ? " underline underline-offset-4" : "")} ><a href={"/"+lang+"/collections/"+slice.primary.collection_section?.uid}>{slice.primary.heading}</a></li>
                                                                                {slice?.items?.map((col_item: any, col_item_index: number)=>{
                                                                                if (col_item.collection_page?.uid) return (
                                                                                    <li className={"block text-[14px] pt-[3px] leading-[18px] cursor-pointer md:pr-8 xxl:pr-0 font-normal mt-3 hover:underline hover:underline-offset-4 capitalize " + (col_item.collection_page?.uid === uid ? " underline underline-offset-4" : "")} key={col_item_index}>
                                                                                        <a href={"/"+lang+"/collections/"+slice.primary.collection_section?.uid+"/"+col_item.collection_page?.uid}>{col_item.collection_page?.uid?.replace(/---/g, "-+-").replace(/-/g, " ")}</a>
                                                                                    </li>
                                                                                ) 
                                                                                return null
                                                                                })}
                                                                            </ul>
                                                                        )
                                                                    }
                                                                })}
                                                                </div>
                                                                <div className="col-span-12 md:col-span-6">
                                                                {item.menu_data?.slices?.map((slice: any, col_index: number)=>{
                                                                    if(slice.slice_type === "collection_set" && slice.primary.column > 1) {
                                                                        return (
                                                                            <ul className="mb-4" key={col_index}>
                                                                                <li className={"block text-[14px] pt-3 leading-[18px] cursor-pointer md:pr-8 xxl:pr-0 font-semibold mt-3 hover:underline hover:underline-offset-4 " + (slice.primary.collection_section?.uid?.trim().toLowerCase().replace(/ /g, "-") === uid ? " underline underline-offset-4" : "")} ><a href={"/"+lang+"/collections/"+slice.primary.collection_section?.uid}>{slice.primary.heading}</a></li>
                                                                                {slice?.items?.map((col_item: any, col_item_index: number)=>{
                                                                                if (col_item.collection_page?.uid) return (
                                                                                    <li className={"block text-[14px] pt-[3px] leading-[18px] cursor-pointer md:pr-8 xxl:pr-0 font-normal mt-3 hover:underline hover:underline-offset-4 capitalize " + (col_item.collection_page?.uid === uid  ? " underline underline-offset-4" : "")} key={col_item_index}>
                                                                                        <a href={"/"+lang+"/collections/"+slice.primary.collection_section?.uid+"/"+col_item.collection_page?.uid}>{col_item.collection_page?.uid?.replace(/---/g, "-+-").replace(/-/g, " ")}</a>
                                                                                    </li>
                                                                                ) 
                                                                                return null
                                                                                })}
                                                                            </ul>
                                                                        )
                                                                    }
                                                                })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                    :<></>}
                                                </div>
                                                <div className={(item.menu_data.columns !== "2" ? "col-span-12 md:col-span-8 lg:col-span-10" : "col-span-12 md:col-span-6 lg:col-span-8") + " justify-end hidden md:flex items-start"}><PrismicNextImage field={item.menu_data.section_image} fallbackAlt="" className="md:h-[75vh] max-h-[713px] object-cover w-full mt-2"/></div>
                                            </div>
                                            
                                        </div>
                                )
                            }
                            return null
                        })} */}
                    </div>
                </div>
            :<></>}
            </>
    )
}

export default MegaMenus
