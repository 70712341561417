'use client'
import { Fragment } from 'react' 
import { getCountryData } from '@/components/getCountryData'
import { TheLookTile } from '@/components'
import TinyArrow from '@/assets/icons/tiny-arrow.svg'
import _ from 'lodash'

interface TheLookAdminProps {
    productId?: any | null
    lookData?: any | null
    lang?: any | null
    slice?: any | null
    rules?: any | null
}

const TheLookAdmin:React.FC<TheLookAdminProps>  = ({ lookData, lang, productId, slice, rules}) => {
        const adminTiles = [0,1,2,3,4,5]

        const { currency, priceColumn } = getCountryData(lang);

        return (
            <div className="border-t-[1px] border-t-warmcharcoal bg-offwhite">
                <h2 className="font-sangbleu font-medium text-[20px] leading-[32px] md:text-[25px] md:leading-[32px] px-5 lg:px-[54px] pt-[104px] pb-8">{slice?.primary?.heading ? slice?.primary.heading :'Complete the look'}</h2>
                <div className="relative grid grid-cols-12 px-4 md:px-12 gap-4 h-full ">
                    {/* <TinyArrow className="hidden md:block absolute top-[30%] lg:top-[40%] left-4"/> */}
                    {adminTiles?.map((item: any, index: number)=>{
                        return ( 
                            <Fragment key={index}>
                                <TheLookTile tileIndex={index} priceColumn={priceColumn} lookData={lookData} currency={currency} productId={productId} rules={rules}/>
                            </Fragment>
                    )})}
                    {/* <TinyArrow className="hidden md:block absolute top-[30%] lg:top-[40%] right-4 rotate-180"/> */}
            </div> 
        </div>
    )
}

export default TheLookAdmin
