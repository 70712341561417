'use client'
import {useState} from 'react'

interface AccountEditProps {
    email?: any | null
}

const AccountEdit:React.FC<AccountEditProps> = ({email}) => {

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleForgotPasswordSubmit = async (email: any) => {

        const res = await fetch('/api/account/send-password-reset', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
        });

        if (res.ok) {
        setMessage('Password reset email sent, please check your inbox.');
        setError('');
        } else {
        const data = await res.json();
        setError(data.error || 'An error occurred');
        setMessage('');
        }
    };

    return (
        <div className="grid grid-cols-12 mt-24">
            <div className="col-span-12 md:col-span-4">
                {error ==='' && message ==='' ?
                    <button type="button" className="w-full border-[1px] border-warmcharcoal text-center text-warmcharcoal font-norma font-medium px-3 py-2 text-[14px] cursor-pointer hover:text-offwhite hover:bg-warmcharcoal" onClick={()=>handleForgotPasswordSubmit(email)}>Reset my password</button>
                    : <p className="text-warmcharcoal font-norma font-medium py-2 text-[14px]">{error}{message}</p>}
            </div>
            <div className="py-2 pb-12 md:col-span-4"></div>
            <div className="col-span-12 md:col-span-4">
                <button type="button" className="w-full border-[1px] border-warmred text-center text-warmred font-norma font-medium px-3 py-2 text-[14px] cursor-pointer hover:text-offwhite hover:bg-warmred">Archive my account</button>
            </div>
        </div>

    )
}

export default AccountEdit