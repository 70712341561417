'use client';

import { useRef, useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';
import Moment from 'moment';

interface MegaMenuButtonProps {
    alertPanel?: any | null;
    menuOpen?: any | null;
    setMenuOpen?: any | null;
    theme?: any | null;
    megaOpen?: any | null;
    setMegaOpen?: any | null;
    local?: any | null;
    chrome?: any | null
}

const MegaMenuButton: React.FC<MegaMenuButtonProps> = ({ menuOpen, setMenuOpen, theme, megaOpen, setMegaOpen, alertPanel, local, chrome }) => {
    const [currentMenu, setCurrentMenu] = useState(1);
    const [isClient, setIsClient] = useState(false);
    const { y } = useWindowScroll();

    useEffect(() => {
        const storedMenu = localStorage.getItem('savedCurrent');
        if (storedMenu) {
            setCurrentMenu(Number(storedMenu));
        }
        setIsClient(true); // Indicate that the component has been hydrated
    }, []);

    let topClass = "top-[30px]"; // Default class
    if (isClient) {
        topClass = (alertPanel === true && chrome=== true && (!local?.time || local?.time < Moment().format('YYYY-MM-DD'))) ? "top-[78px]" : "top-[30px]";
    }

    let testTheme = theme;
    if (y > 100) {
        testTheme = false;
    }

    const combinedClassName = `${topClass} fixed left-[calc(100vw_-_60px)] lg:left-[calc(100vw_-_84px)] lg:right-[54px] cursor-pointer z-20 w-[40px]`;

    return (
        <div
            className={combinedClassName}
            onClick={() => {
                setMenuOpen(!menuOpen);
                setMegaOpen(Number(currentMenu));
                document.body.style.overflow = "hidden";
            }}
        >
            {menuOpen === false ? (
                <div className={`${testTheme === true ? "border-offwhite" : "border-warmcharcoal/50"} border-y-[1px] h-[10px] w-[36px]`}></div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default MegaMenuButton;
