'use client';
import { useState, useEffect, Fragment} from 'react';
import _ from 'lodash'
import { getCountryData } from '@/components/getCountryData'
import { Filters, QuickFind } from '@/components'
import NextLink from 'next/link'
import Image from 'next/image'

interface ShowAllProps {
    productSet?: any | null
    productType?: any | null
    lang?: any | null
    rules?: any | null
    quickFind?: any | null
    collectionProjectsArray?: any | null
}

const ShowAll: React.FC<ShowAllProps> = ({ productSet, productType, lang, rules, quickFind, collectionProjectsArray}) => {
    
    const { currency, priceColumn } = getCountryData(lang);

    // console.log('QF: ', quickFind)

    const findBestRuleForProduct = (product: any, rules: any) => {
        let bestRule: any = null;
        let highestAmount = 0;
      
        rules.forEach((rule: any) => {
            rule.rules_json?.products.forEach((rulesProducts: any) => {
                if (rulesProducts.category?.id && product.categoryIdArray?.includes(rulesProducts.category.id)) {
                    // console.log('cat: ', rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.projectname && product.projectName === rulesProducts.projectname) {
                    // console.log('proj: ', rulesProducts.projectname);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.productdata && rulesProducts.productdata.id === product.id) {
                    // console.log('prods: ', rulesProducts.productdata.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts === "all") {
                    // console.log('all: ', rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
            });
        });
        return bestRule?.rules_json?.rules;
    };

    const [filterSet, setFilterSet] = useState(productSet) as any

    function filterProducts(products: any, filters: any) {
        return products.filter((productWrapper: any) => {
            const product = productWrapper.productdata;
    
            // Check if any filters are applied
            const hasAnyFilter = filters.productSubtype?.length > 0 || 
                                 filters.projectName?.length > 0 || 
                                 filters.option1?.length > 0 || 
                                 filters.option2?.length > 0;
    
            // If no filters are applied, return all products
            if (!hasAnyFilter) return true;
    
            // Match Product Subtype (narrow down with the first filter)
            const matchesProductSubType = 
                !filters.productSubtype || 
                filters.productSubtype.length === 0 || 
                filters.productSubtype.some((subtype: string) => 
                    subtype.toLowerCase() === product.productSubtype?.toLowerCase()
                );
    
            // Match Project Name (allow widening on subsequent filters)
            const matchesProjectName = 
                !filters.projectName || 
                filters.projectName.length === 0 || 
                filters.projectName.some((name: string) => 
                    name.toLowerCase() === product.projectName?.toLowerCase()
                );
    
            // Match Options (option1 and option2)
            const matchesOptions = 
                (!filters.option1 && !filters.option2) || 
                product.productOptions?.some((option: any) => {
                    const matchesOption1 = 
                        !filters.option1 || 
                        filters.option1.length === 0 || 
                        filters.option1.includes(option.option1);
    
                    const matchesOption2 = 
                        !filters.option2 || 
                        filters.option2.length === 0 || 
                        filters.option2.includes(option.option2);
    
                    return matchesOption1 && matchesOption2;
                });
    
            // Start with narrowing logic (AND logic on the first filter)
            let matches = false;
    
            if (filters.productSubtype?.length > 0) {
                matches = matches || matchesProductSubType;
            }
            if (filters.projectName?.length > 0) {
                matches = matches || matchesProjectName;
            }
            if (filters.option1?.length > 0 || filters.option2?.length > 0) {
                matches = matches || matchesOptions;
            }
    
            // Return true if the product matches at least one filter
            return matches;
        });
    }
    
    const updateResultList = (filters: any) => {
        const filteredResults = filterProducts(productSet, filters);
        setFilterSet(filteredResults); // Properly update the state
    };
    
    
    
    return (
        <>
        <Filters updateResultList={updateResultList} results={filterSet?.length} quickFind={quickFind} collectionProjectsArray={collectionProjectsArray}/>
        <div className="grid grid-cols-12 py-4 gap-5 lg:gap-8 px-4 lg:px-[50px]">
            {filterSet?.map((item: any, index: any)=>{
            const retailValues = _.flatMap(item, product => item.productdata.productOptions.map((option : any) => option.priceColumns?.[priceColumn]));
            const nonZeroRetailValues = _.filter(retailValues, value => value !== 0);
            const lowestRetail = _.min(nonZeroRetailValues);
            if(item.productdata.images[0]?.link && lowestRetail > 0) {

            const applicablePromotion = findBestRuleForProduct(item.productdata, rules) as any

            let discountLabelType = "";
            let discountPrice = lowestRetail
            let discounted = 0

            if (applicablePromotion) {
                // console.log(item.productdata, applicablePromotion)
                discounted = applicablePromotion.amount;
                discounted = applicablePromotion.type === "percentage" ? discounted / 100 : discounted;

                discountPrice = lowestRetail - lowestRetail * discounted;
                discountLabelType = applicablePromotion.type === "percentage" ? `${applicablePromotion.amount}% off` : `${currency} ${applicablePromotion.amount} off`;
            }

            return (
                <div className="col-span-12 md:col-span-6 lg:col-span-4 origin-center hover:opacity-60 cursor-pointer duration-300 mb-16 relative" key={index}>
                <NextLink href={("/"+lang+"/"+item.productdata.productType+"/"+item.productdata.productSubtype?.trim()).toLowerCase().replace(/ /g, "-").replace(/-&-/g, "---").replace(/-+-/g, "---")+"/"+item.productdata.name?.trim()?.toLowerCase().replace(/ /g, "-")+"/"+item.productdata.productOptions[0].option1?.trim()?.toLowerCase().replace(/ /g, "-")}>
                {discountLabelType ? <div className="absolute top-0 right-0 bg-warmgrey text-white font-norma font-medium flex items-center justify-center text-[14px] h-[30px] px-4">up to {discountLabelType}</div>:<></>}
                <div className="relative">
                    <Image src={item.productdata.images[0].link} className="w-full object-cover aspect-square relative -z-[1]" width={600} height={700} alt={item?.productdata?.name+". From $"+Number(lowestRetail).toFixed(2)+". Available at George Street Linen"}/>
                    {item.product_count > 1 ? <div className="absolute bottom-0 left-0 bg-warmgrey text-white font-norma font-medium flex items-center justify-center text-[14px] h-[30px] px-4 z-[1]">{item.product_count} colours available</div>:<></>}
                </div>
                    <div className="grid grid-cols-12 py-6 h-[100px]">
                    <div className="col-span-6 lg:col-span-7">
                        <p className="font-norma font-medium text-[16px] leading-[24px]">{item.productdata.name}</p>
                    </div>
                    <div className="col-span-6 lg:col-span-5 flex justify-end">
                    <p className="font-norma font-normal text-[16px] leading-[24px] text-right">
                        {Number(lowestRetail-lowestRetail*discounted).toFixed(2) !== Number(lowestRetail).toFixed(2) ?
                        <><span className="line-through text-warmred inline-block">{currency} {Number(lowestRetail).toFixed(2)}</span><br/></>: <></>}
                        From {currency} {Number(lowestRetail-lowestRetail*discounted).toFixed(2)}</p>
                    </div>
                    </div>
                    <button className="font-forma font-medium w-full py-[13px] text-offwhite text-[14px] leading-[14px] bg-warmcharcoal text-center">Buy now</button>
                </NextLink>
                
                </div>
            )
            }
            return <Fragment key={index}></Fragment>
            })}
        </div>
        </>
    )
};

export default ShowAll;
