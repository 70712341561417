'use client'
import * as React from 'react'
import dynamic from 'next/dynamic'
import { useIntersection } from 'react-use';

// Lazy load ReactPlayer
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

interface ReactPlayerModuleProps {
    playerData?: any | null
    background?: string | null
}

const ReactPlayerModule: React.FC<ReactPlayerModuleProps> = ({ playerData, background }) => {
    const intersectionRef = React.useRef(null) as any
    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0.7
    }) as any

    return (
        <div ref={intersectionRef} className={`${background || ''} video-container`}>
            {playerData && playerData.video?.url && (
                <ReactPlayer
                    url={playerData.video.url}
                    loop
                    playing={intersection?.intersectionRatio > 0.7}
                    playsinline
                    muted
                    width="100%"  // Responsive width
                    height="auto" // Responsive height
                    className="video-player"
                    fallback={<div>Loading video...</div>}  // Optional fallback for user feedback
                />
            )}
        </div>
    );
}

export default ReactPlayerModule;
