import NextLink from 'next/link';
import NextImage from 'next/image';
import { getCountryData } from '@/components/getCountryData';
import _ from 'lodash'

interface ProductColoursProps {
    colours?: any | null
    params?: any | null
    lang?: any | null
}

const ProductColours:React.FC<ProductColoursProps>  = ({ colours, params, lang }) => {

    const { currency, priceColumn } = getCountryData(lang);

    return (
        <div className="w-screen lg:w-full px-4 lg:pl-0 lg:pr-[54px] mt-16 md:pb-4 lg:pb-0 scroll-mt-[100px]" id="productColours">
            {/* <h4 className="mb-2 font-norma font-medium text-[14px] leading-[18px] pb-3">Select options for {colours[0].productdata?.name}</h4> */}
            {/* <h4 className="mb-2 font-norma font-medium text-[14px] leading-[18px] pb-3">Select options</h4> */}
            <div className="grid grid-cols-12 pt-3 border-t-[1px] border-t-warmcharcoal">
                <div className="col-span-12 md:col-span-3 pr-5">
                    <h4 className="font-norma font-semibold text-[14px] leading-[18px]">Colour</h4>
                </div>
                <div className="col-span-12 md:col-span-9 w-full py-4 md:py-0 md:pl-1 ">
                    {colours?.map((item: any, index: number)=>{

                        // check to see a price exists
                        const retailValues = _.flatMap(item, product => item.productdata.productOptions
                            .filter((option: any) => option !== null && option !== undefined)
                            .map((option: any) => option?.priceColumns[priceColumn]));
                        const nonZeroRetailValues = _.filter(retailValues, value => value !== 0);
                        const lowestRetail = _.min(nonZeroRetailValues);

                        if(item.productdata.images?.length > 0 && lowestRetail > 0) {
                            return (
                                <div title={item.productdata?.productOptions[0]?.option1} key={index} className={"w-[50px] h-[50px] lg:w-[15.67%] lg:h-[5.4vw] inline-block mb-3 mr-[1%]"} >
                                    <NextLink href={`/${params.lang}/${params.producttype}/${params.product}/${params.lineitem}/${item.productdata?.productOptions[0]?.option1.trim().toLowerCase().replace(/ /g, "-")}`}>
                                        {item.productdata.images?.length ? 
                                        <NextImage src={item.productdata.images[item.productdata.images?.length-1].link} width={60} height={60} alt="" className={" w-full aspect-square object-cover border-[2px] text-[0px] "+ (item.productdata?.productOptions[0]?.option1.trim().toLowerCase().replace(/ /g, "-") === params.color ? "border-warmcharcoal" : "border-transparent" )}/>
                                        : <></>}
                                    </NextLink>
                                    <p className="absolute text-warmcharcoal font-norma font-medium text-[12px] h-[30px]">
                                        {item.productdata?.productOptions[0]?.option1.trim().toLowerCase().replace(/ /g, "-") === params.color ? item.productdata?.productOptions[0]?.option1 :""}
                                    </p>
                                </div>
                            )
                        } 
                        return <></>
                    })}
                </div>
            </div>
        </div>
    )
}

export default ProductColours