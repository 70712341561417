'use client'
import { useState, useRef, useEffect, Fragment } from 'react' 
import { getCountryData } from '@/components/getCountryData'
import { useForm, Controller } from 'react-hook-form'; 
import { CouponLineItem } from '@/components'
import Tick from '@/assets/icons/tick.svg'

import _ from 'lodash'
import Moment from 'moment'

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface PromotionPanelAdminProps {
    coupons?: any | null
}

    const PromotionPanelAdmin:React.FC<PromotionPanelAdminProps>  = ({ coupons }) => {

        const { register, handleSubmit, watch, reset, formState: { errors }, setValue } = useForm();

        const [couponData, setCouponData] = useState(coupons?.length > 0 ? coupons : null) as any
        const [updating, setUpdating] = useState(false)

        return (
            
            <div className="px-6 md:px-[50px] py-24 font-norma">
                <h1 className="font-sangbleu font-light text-[24px]">Klaviyo Simple Coupon Management</h1>
                <p className="mb-12 mt-4 ">This panel only allows you to create and remove klaviyo coupons. <a href="https://www.klaviyo.com/coupons/api" className="underline underline-offset-4">Go to klaviyo for everything else</a></p>
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-7">
                        <h2 className="font-norma font-semibold text-[21px] leading-[18px] mt-6 mb-12">
                            <span className="inline-flex text-offwhite bg-warmcharcoal rounded-full text-[16px] font-semibold h-[28px] w-[28px] items-center justify-center mr-3 relative -top-[2px]">A</span>
                            View and remove active coupons
                        </h2>
                        {couponData?.map((item: any, index: any)=>{
                            return (
                                <CouponLineItem coupon={item} row={index} key={index} setCouponData={setCouponData}/>
                            )
                        })}
                    </div>
                    <div className="col-span-1"></div>
                    <div className="col-span-4 border-l-[1px] border-warmcharcoal px-12 py-6">
                        <h2 className="font-norma font-semibold text-[21px] leading-[18px] mb-12">
                            <span className="inline-flex text-offwhite bg-warmcharcoal rounded-full text-[16px] font-semibold h-[28px] w-[28px] items-center justify-center mr-3 relative -top-[2px]">B</span>
                            Create new coupon
                        </h2>
                        <form onSubmit={handleSubmit(async (data: any) => {
                            setUpdating(true);
                            const createCoupon = await fetch('/api/klaviyo/coupons/coupon-create', {method: "post", body: JSON.stringify(data)})
                            const couponResult = await createCoupon.json();
                            const updateCoupons = await fetch(`/api/klaviyo/coupons`)
                            const newCoupons = await updateCoupons.json();
                            setCouponData(newCoupons)
                            reset();
                            setUpdating(false);
                        })}>
                            {errors.id && <p className="text-[12px] mb-4">{errors.id.message as any}</p>}
                            <input type="text" {...register('id', { required: true,
                                                                    pattern: {
                                                                    value: /^[0-9_A-Z]+$/,
                                                                    message: 'Only alphanumeric characters and underscores are allowed',
                                                                    },
                                                                    onBlur: (e) => {
                                                                    const value = e.target.value;
                                                                    // Clean up the input by removing disallowed characters
                                                                    const cleanedValue = value.toUpperCase().replace(/[^0-9_A-Z]/g, '');
                                                                    // Update the input's value directly in the DOM
                                                                    e.target.value = cleanedValue;
                                                                    // Update the form state
                                                                    setValue('id', cleanedValue, {
                                                                        shouldValidate: true,
                                                                        shouldDirty: true,
                                                                    });
                                                                    },
                                                                })}
                                placeholder="CODE" className="w-full py-3 px-4 mb-4 uppercase focus:ring-warmcharcoal focus:ring-[1px] focus:outline-none"/>
                            <input type="number" className="  py-3 px-4 mb-4 w-[100px] inline-block mr-3 focus:ring-warmcharcoal focus:ring-[1px] focus:outline-none" {...register('value', {required: true})}/>
                            <label className="inline-flex items-center relative top-2 cursor-pointer">
                                <Tick className="stroke-offwhite absolute left-2"/>
                                <input type="checkbox" {...register('type', {required: true})} className="appearance-none h-6 w-6 border border-warmcharcoal rounded-sm checked:bg-warmcharcoal checked:border-warmcharcoal focus:outline-none "/>
                                <span className="ml-2">This is a % value</span>
                            </label>
                            <textarea {...register('name', {required: true})} placeholder="Description" rows={4} className="w-full py-3 px-4 mb-4 focus:ring-warmcharcoal focus:ring-[1px] focus:outline-none"></textarea>
                            {!updating ?
                                <input type="submit" defaultValue="Create coupon" className="w-full py-3 px-4 mb-4 bg-warmcharcoal text-offwhite font-norma font-semibold cursor-pointer hover:opacity-60"/>
                                : <div className="w-full py-3 px-4 mb-4 bg-warmcharcoal/50 text-offwhite font-norma font-semibold text-center">Updating</div>}
                                
                        </form>
                    </div>
                </div>
                
                            
            </div>
    )
}

export default PromotionPanelAdmin
