'use client'
import { useState } from 'react'
import Default from '@/assets/icons/wishlist-empty.svg'
import Delete from '@/assets/icons/trash.svg'

interface AddressTileProps {
    address?: any | null
    setActiveAddress?: any | null
    activeAddress?: any | null
    index?: number | null
    uuid?: any | null
    setHideTile?: any | null
    defaultToggle?: any | null
}

const AddressTile:React.FC<AddressTileProps>  = ({ address, setActiveAddress, activeAddress, index, uuid, setHideTile, defaultToggle}) => {

    const delivery = address.delivery;
    const billing = address.billing;

    const removeAddress = async (event: any, index: any) =>{
        event.stopPropagation();
        const removeAddress = await fetch(`/api/account/addresses?del=${index}&uuid=${uuid}`);
        const deleted = await removeAddress.json();
        setHideTile(index)
    }

    return ( 
        <>
            <h4 className="font-sangbleu font-medium mb-2">Delivery</h4>
            <h4 className="font-semibold text-[12px] leading-[16px]">{delivery.deliveryFirstName} {delivery.deliveryLastName}</h4>
            <p className="mb-6 text-[12px] leading-[16px]" dangerouslySetInnerHTML={{__html:delivery.deliveryAddress.formatted_address.substring(0,24) + " ... <br/>" +delivery.deliveryCity}}/>
            <h4 className="font-sangbleu font-medium my-2">Billing</h4>
            <p className="font-semibold text-[12px] leading-[16px]">{billing.billingFirstName} {billing.billingLastName}</p>
            <p className="mb-2 text-[12px] leading-[16px]" dangerouslySetInnerHTML={{__html: billing.billingAddress.formatted_address.substring(0,24) + " ... <br/>" +billing.billingCity}}/>
            <Default className={"absolute top-4 right-4 w-[16px] " +(activeAddress === index ? "fill-warmcharcoal" : "")} onClick={(e: any)=>defaultToggle(e, index)}/>
            <Delete className="absolute bottom-4 right-4 w-[24px] hover:opacity-60" onClick={(e: any)=>removeAddress(e, index)}/>
        </>
    )
} 

export default AddressTile