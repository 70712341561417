'use client'
import { useState, useEffect} from 'react'
import { MegaMenus, HeaderMenu, LoginForm, CartData, SearchMenu, PromoCTA } from '@/components'
import { useLocalStorage } from 'react-use';
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useSession, signOut } from "next-auth/react"
import Close from '@/assets/icons/close.svg'

interface HeaderMenuControllerProps {
    cin7Menu?: any | null
    theme?: any | null
    megaMenus?: any | null
    menus?: any | null
    uid?: any | null
    lang?: any | null
    menuOrder?: any |null
    defImage?: any |null
    subMenu?: any | null
    rules?: any | null
    promos?: any | null
    alerts?: any | null
    chrome?: any | null
}

const HeaderMenuController:React.FC<HeaderMenuControllerProps> = ({chrome, theme, megaMenus, lang, menus, cin7Menu, uid, menuOrder, defImage, subMenu, rules, promos, alerts}) => {
    const router = useRouter();
    const pathname = usePathname();
    const { data: session, status } = useSession()

    const user = session?.user as any;
    const [ menuOpen, setMenuOpen ] = useState(false)
    const [ megaOpen, setMegaOpen ] = useState(1)
    const [ savedCurrent, setSavedCurrent] = useLocalStorage('savedCurrent', 1);
    const [ countryMenu, setCountryMenu ] = useState(false)
    const [ account, setAccount ] = useState(false)
    const [ showCart, setShowCart ] = useState(false)
    const [ showSearch, setShowSearch ] = useState(false)
    
    const searchParams = useSearchParams();
    let authState = searchParams.get("auth");  
    let token = searchParams.get("token");
    let vtoken = searchParams.get("vtoken");

    useEffect(()=>{
        switch(authState) {
            case 'reset' :
                setAccount(true)
            break;
            case 'login' :
                setAccount(true)
            break;
            case 'logged-out' :
                setAccount(true)
            break;
            case 'cart' :
                setShowCart(true)
            break;
            default:
        }
    }, [])

    return (
        <>  
            {rules?.map((rule: any, index: number)=>{ 
                return (
                    <PromoCTA rule={rule} user={user} key={index} zLevel={index}/>
                )
            })}
            <section className="max-w-screen">
                <HeaderMenu chrome={chrome} menus={menus} theme={theme} lang={lang} uid={uid} menuOpen={menuOpen} setMenuOpen={setMenuOpen}  megaOpen={savedCurrent} setMegaOpen={setMegaOpen} countryMenu={countryMenu} setCountryMenu={setCountryMenu} account={account} setAccount={setAccount} showCart={showCart} setShowCart={setShowCart} setShowSearch={(setShowSearch)} showSearch={showSearch}/>
            </section>
            <MegaMenus megaOpen={megaOpen} setMegaOpen={setMegaOpen} menuOpen={menuOpen} setSavedCurrent={setSavedCurrent} setMenuOpen={setMenuOpen} megaMenus={megaMenus} menuOrder={menuOrder} theme={theme} cin7Menu={cin7Menu} subMenu={subMenu} defImage={defImage} lang={lang} uid={uid} countryMenu={countryMenu} setCountryMenu={setCountryMenu} account={account} setAccount={setAccount} showCart={showCart} setShowCart={setShowCart} setShowSearch={setShowSearch}/>
            {account === true ? 
            <div className="relative max-w-screen">
                <LoginForm authState={authState} token={token} vtoken={vtoken} setAccount={setAccount} welcomeImage={menus.data.welcome_image} lang={lang}/>
            </div>
            :<div></div>}
            <div id="cartPanel" className={"fixed top-0 bottom-0 z-[110] transform-all duration-500 shadow-lg w-screen " + (showCart === true ? "left-0 right-0 md:left-auto" : "-right-[150vw]")}>
                <CartData lang={lang} setShowCart={setShowCart} showCart={showCart} rules={rules}/>
            </div>
            <div className={"fixed top-0 bottom-0 z-[140] transform-all duration-500 shadow-lg overflow-hidden overflow-y-scroll bg-offwhite  w-screen md:w-[50vw] " + (showSearch === true ? "right-0" : "-right-[150vw]")}>
                <SearchMenu setShowSearch={setShowSearch} showSearch={showSearch} lang={lang}/>
            </div>
        </>
    )
}

export default HeaderMenuController
