import _ from 'lodash'
import { sql } from '@vercel/postgres'
import NextLink from 'next/link'
import NextImage from 'next/image'
import Moment from 'moment'

interface ManageAdminPanelProps {
    lang?: any | null
}


const ManageAdminPanel:React.FC<ManageAdminPanelProps>  = async ({ lang }) => {

    const { rows } = await sql`SELECT * 
                                FROM public.promotions 
                                WHERE status NOTNULL`;

    const { rows: products } = await sql`SELECT p.*
                                            FROM public.products p
                                            LEFT JOIN get_the_look gtl ON CAST(p.product_id AS text) = gtl.product_id
                                            WHERE gtl.product_id IS NULL AND CAST(p.productdata->>'stockControl' AS int) > 0
                                            LIMIT 50
                                            `;

    const [current, previous] = _.partition(rows, (item: any) => item.status);

    return (
        <div className="px-6 md:px-[50px] py-24">
            <div className="grid grid-cols-12 gap-8">
                <div className="col-span-12 lg:col-span-5">
                    <h2 className="font-sangbleu font-medium text-[21px]">Current promotions</h2>
                    <ul className="mt-4 mb-24">
                        {current?.map((item: any, index: number)=>{
                            return (
                                <li className="font-norma border-b-[1px] border-b-warmcharcoal py-4" key={index}>
                                    <NextLink href={`/manage/promotions/${item.id}`}>
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-6 font-medium pr-4 ">{item.details_json.name}</div>
                                            <div className="col-span-4 text-[14px]"> Ends: {Moment(item.rules_json.schedule[1]).format("DD MMM YYYY")}</div>
                                            <div className="col-span-2 text-[12px]"><span className="border-[1px] border-warmcharcoal px-3 hover:bg-warmcharcoal hover:text-offwhite inline-block w-full text-center">Details</span></div>
                                        </div>
                                    </NextLink>
                                </li>
                            )
                        })}
                    </ul>
                    <h2 className="font-sangbleu font-medium text-[21px]">Previous promotions</h2>
                    <ul className="mt-4 mb-24">
                        {previous?.map((item: any, index: number)=>{
                            return (
                                    <li className="font-norma border-b-[1px] border-b-warmcharcoal py-4" key={index}>
                                        <NextLink href={`/manage/promotions/${item.id}`}>
                                            <div className="grid grid-cols-12">
                                                <div className="col-span-6 font-medium pr-4 ">{item.details_json.name}</div>
                                                <div className="col-span-4 text-[14px] line-through">{Moment(item.rules_json.schedule[1]).format("DD MMM YYYY")}</div>
                                                <div className="col-span-2 text-[12px]"><span className="border-[1px] border-warmcharcoal px-3 hover:bg-warmcharcoal hover:text-offwhite inline-block w-full text-center">Details</span></div>
                                            </div>
                                        </NextLink>
                                    </li>
                                )
                            
                        })}
                    </ul>
                    </div>
                    <div className="col-span-12 lg:col-span-1"></div>
                    <div className="col-span-12 lg:col-span-6">
                        <h2 className="font-sangbleu font-medium text-[21px]">Products missing get the look</h2>
                        <div className="grid grid-cols-12 gap-4 font-norma pt-8">
                            {products?.map((item: any, index: number)=>{
                                const iframeSrc =(`/${lang}/${item.productdata.productType}/${item.productdata.productSubtype}/${item.productdata.name.trim().toLowerCase().replace(/ /g, "-")}/${item.productdata?.productOptions[0]?.option1}`).trim().toLowerCase().replace(/ /g, "-")
                                return (
                                    <div className="col-span-6 lg:col-span-4 xxl:col-span-2 border-[1px] border-warmgrey/50 rounded-md hover:bg-warmcharcoal hover:text-offwhite duration-300" key={index}>
                                        <NextLink href={`/manage/products?pid=${iframeSrc}`} >
                                            <div className="grid grid-cols-12 gap-4 p-[10px]">
                                                <div className="col-span-9 flex text-[12px] leading-[18px]">{item.productdata.name}</div>   
                                            </div>                                
                                        </NextLink>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
    )

}

export default ManageAdminPanel